import clsx from "clsx";

import Link from "next/link";
import router from "next/router";
import { useEffect } from "react";

const PrimaryButton = ({
  target,
  isFull = false,
  isHeader = false,
  isRounded = true,
  outline = false,
  color = null,
  darkBackground = false,
}) => {
  const handleClick = (text, url) => {
    window.dataLayer?.push({
      event: "event",
      eventProps: {
        category: "Button Click",
        action: text,
        label: url,
      },
    });
  };

  let href;

  let newTab = false;

  useEffect(() => {
    if (
      router.pathname === "/partner-portal/[slug]" &&
      target.element?.sectionHandle === "partnerPortal"
    ) {
      newTab = false;
      if (!!target.element.downloadableFiles[0]) {
        href = target.element.downloadableFiles[0].url;
        newTab = true;
      }
    } else if (router.pathname === "/partner-portal/[slug]") {
      newTab = true;
    }
  });

  return (
    <Link
      href={
        href
          ? href
          : target.element?.uri
          ? `/${target.element.uri}`
          : target.url
      }
      target={newTab ? "_blank" : target.target}
      onClick={() => handleClick(target.text, target.url)}
      className={clsx(
        "PrimaryButton",
        "flex items-center justify-center",
        {
          "h-20 px-5": isHeader,
          "h-10 px-5": !isHeader
        },
        { "rounded-sm": isRounded },
        "transition-colors",
        {
          "bg-purple-01 text-white-01 hover:bg-black-01 hover:text-white-01":
            !outline && !darkBackground && color !== "black" && color !== "seafoam-green" && !isHeader,
          "bg-purple-01 text-white-01 hover:bg-white-02 hover:text-black-01":
            !outline && darkBackground && color !== "black" && color !== "seafoam-green" && !isHeader,
          "bg-black-01 text-white-01 hover:bg-purple-01 hover:text-white-01":
            !outline && !darkBackground && color === "black" && !isHeader,
          "bg-black-01 text-white-01 hover:bg-white-02 hover:text-black-03":
            !outline && darkBackground && color === "black" && !isHeader,
          "border border-purple-01 text-purple-01 hover:bg-purple-01  hover:text-white-01":
            outline && color !== "seafoam-green" && !isHeader,
          "bg-seafoam-green-01 text-black-02 hover:bg-seafoam-green-02 focus:bg-seafoam-green-02 active:bg-seafoam-green-03" : color=="seafoam-green"
        },
        {
          "w-full": isFull,
        }
      )}
    >
      <span className={clsx("PrimaryButton__label", "whitespace-nowrap")}>
        {target.text}
      </span>
    </Link>
  );
};

export default PrimaryButton;
